









  import { Component, Prop, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'
  import { Suggestions } from '@one/types'

  const layout = namespace('layout')
  const products = namespace('products')

  @Component({
    components: {
      OneCmsCategoriesList: () => import('~/components/organisms/list/OneCmsCategoriesList.vue'),
    },
  })
  export default class CategoriesDropdownComponent extends Vue {
    @layout.State backdrop: any
    @layout.State menuCategoriesShow: any
    @layout.Getter getCurrency: any
    @layout.Getter isGrossSelected: any
    @layout.Mutation('TOGGLE_ACCOUNT_MENU') toggleMenuAccount: any
    @layout.Mutation('TOGGLE_BACKDROP') backdropRun: any
    @layout.Mutation('TOGGLE_CATEGORIES_MENU') toggleMenuCategoriesShow: any

    @products.Action updateSearchString: any

    @Prop({
      type: Boolean,
      required: true,
    })
    readonly disabledOnIndexPage!: boolean

    togglingCategoriesMenuHandler: any = null

    get shouldShowComponent(): boolean {
      return !this.$tenantConfig.isPlatformClosed || this.$auth.isAuthenticated
    }

    get shouldShowDropdown(): boolean {
      return !this.backdrop && this.$utils.isIndexPage ? !this.disabledOnIndexPage : true
    }

    mouseOver() {
      if (this.shouldShowDropdown) {
        clearTimeout(this.togglingCategoriesMenuHandler)
        this.togglingCategoriesMenuHandler = setTimeout(() => {
          this.backdropRun(true)
          this.toggleMenuCategoriesShow(true)
        }, 150)
      }
    }

    mouseLeave() {
      clearTimeout(this.togglingCategoriesMenuHandler)
      this.toggleMenuCategoriesShow(false)
      this.backdropRun(false)
    }

    onCategoryClick(category: Suggestions.SuggestedCategory | string) {
      const categoryId = typeof category === 'string' ? category : category.id
      this.updateSearchString('')
      const categoryPath: string | null = this.$routes.getCategoryPath(categoryId)
      if (categoryPath) {
        this.$router.push(categoryPath)
        // TODO: Tutaj zamykał się suggest
      }
    }
  }
